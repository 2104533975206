import BannerOverlay from "../components/BannerOverlay";
import { TeamContent } from "../components/pages/team/Team";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

const Team = () => {
    return (
        <div  className="top-0" style={{background: 'url("https://ik.imagekit.io/dnddecpho/dndream/bgproject_UlxbKFd-j.jpg?updatedAt=1710473718425")', backgroundSize: "cover" ,backgroundPosition: "center" }}>
            <Header />
            <BannerOverlay
                imageUrl="https://ik.imagekit.io/dnddecpho/dndream/D&D_Website_WebBanner_1920x500px_PC2_Ij3QlhYu_.jpg?updatedAt=1731826250613"
                title=""
            ></BannerOverlay>
            <TeamContent />
            <Footer />
        </div>
    );
}

export default Team;
